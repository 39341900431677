import React, { Component } from 'react';
import { db } from './firebase';
import { Redirect } from 'react-router-dom';


class Word extends Component {

	constructor(props) {
		super(props);

		this.state = {
			webword: null,
			clipword: null
		};

		
	    this.fetchWord = this.fetchWord.bind(this);
	}


	fetchWord(){

		let clipRef = db.collection('clipword'),
			pageRef = db.collection('webword'),
			clipQuery = clipRef.where("clipwords", "==", this.props.match.params.id),
			pageQuery = pageRef.where("webwords", "==", this.props.match.params.id);

		clipQuery
		.get()
		.then(collection => {
			let clipwords = collection.docs.map(doc => doc.data())
			this.setState({ clipword: clipwords[0] })	
		})

		pageQuery
		.get()
		.then(collection => {
			let webwords = collection.docs.map(doc => doc.data())
			this.setState({ webword: webwords[0] })})
	}

	componentDidMount(){
		this.fetchWord();
	}



  render() {
  	let clipword = this.state.clipword;
  	let webword = this.state.webword;
  	let apercent = 0, bpercent = 0, cpercent = 0, dpercent = 0;
  	let awebpercent = 0, bwebpercent = 0, cwebpercent = 0, dwebpercent = 0;

  	if (clipword) {

  		let clipword_array = clipword.viewer;
		for(var i = 0; i < clipword_array.length; ++i){
		    if(clipword_array[i].includes(clipword.optionA)){
		        apercent++;
		    }
		    else if(clipword_array[i].includes(clipword.optionB)){
		        bpercent++;
		    }
		    else if(clipword_array[i].includes(clipword.optionC)){
		        cpercent++;
		    }
		    else if(clipword_array[i].includes(clipword.optionD)){
		        dpercent++;
		    }
		}
	}
	if (webword) {

  		let webword_array = webword.viewer;
		for(var i = 0; i < webword_array.length; ++i){
		    if(webword_array[i].includes(webword.optionA)){
		        awebpercent++;
		    }
		    else if(webword_array[i].includes(webword.optionB)){
		        bwebpercent++;
		    }
		    else if(webword_array[i].includes(webword.optionC)){
		        cwebpercent++;
		    }
		    else if(webword_array[i].includes(webword.optionD)){
		        dwebpercent++;
		    }
		}
	}


  	if (this.props.user) {

	  	if(clipword || webword) {

		    return (

		      clipword ?
		      	<>
			      	<section id="single-word-page" className="word-division">
						<div class="block right">
						<h3>Performance Analysis</h3>
							{
								clipword.viewer.length === 0 ?
								<p> No student did the assignment </p>
								:
								<>
								<ul>
									<li><p><b>{parseInt(apercent/clipword.viewer.length*100)}% : </b>{clipword.optionA}</p></li>
									<li><p><b>{parseInt(bpercent/clipword.viewer.length*100)}% : </b>{clipword.optionB}</p></li>
									<li><p><b>{parseInt(cpercent/clipword.viewer.length*100)}% : </b>{clipword.optionC}</p></li>
									<li><p><b>{parseInt(dpercent/clipword.viewer.length*100)}% : </b>{clipword.optionD}</p></li>
								</ul>
								<p>{clipword.viewer.length} students did this assignment</p>
								</>
							}
							
						</div>
					</section>

					<section id="single-word-page" className="word-division">
						<div class="block right">
							<h3>Class Attendance</h3>
							<ul>
								{
									clipword.viewer.length === 0 ?
									<p> None available </p>
									:
									clipword.viewer.map( viewer => <li><b>{viewer}</b></li>)
									
								}
							</ul>
						</div>
					</section>

					<section id="single-word-page" className="word-division">
						<div className="block left">
							<h3> Clipword Details </h3>
							<h2>{clipword.clipwords}</h2>
							<p><a href={"https://www.youtube.com/watch?v=" + clipword.url} target="_blank"> {"https://www.youtube.com/watch?v=" + clipword.url} </a></p>
							<p>{clipword.question}</p>
						</div>
					</section>
				</>
				:

				<>
			      	<section id="single-word-page" className="word-division">
						<div class="block right">
						<h3>Performance Analysis</h3>
							{
								webword.viewer.length === 0 ?
								<p> No student did the assignment </p>
								:
								<>
								<ul>
									<li><p><b>{parseInt(awebpercent/webword.viewer.length*100)}% : </b>{webword.optionA}</p></li>
									<li><p><b>{parseInt(bwebpercent/webword.viewer.length*100)}% : </b>{webword.optionB}</p></li>
									<li><p><b>{parseInt(cwebpercent/webword.viewer.length*100)}% : </b>{webword.optionC}</p></li>
									<li><p><b>{parseInt(dwebpercent/webword.viewer.length*100)}% : </b>{webword.optionD}</p></li>
								</ul>
								<p>{webword.viewer.length} students did this assignment</p>
								</>
							}
						</div>
					</section>

					<section id="single-word-page" className="word-division">
						<div class="block right">
							<h3>Class Attendance</h3>
							<ul>
								{
									webword.viewer.length === 0 ?  
									<p> None available </p>
									:
									webword.viewer.map( viewer => <li><b>{viewer}</b></li>)
									
								}
							</ul>
						</div>
					</section>

					<section id="single-word-page" className="word-division">
						<div className="block left">
							<h3> Webword Details </h3>
							<h2>{webword.webwords}</h2>
							<p><a href={ webword.url} target="_blank"> {webword.url} </a></p>
							<p>{webword.question}</p>
						</div>
					</section>
				</>


		    );
		}

		return(<img className="loader-spin" src="/img/loader.gif" alt="loading..." title="loading" />)

	}

	return(
	<Redirect to="/" />
	)


  }
}


export default Word;

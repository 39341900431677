import React, { Component } from 'react';

class Footer extends Component {
  render() {
    return (
      <footer>
			<p>World's first Assignment Portal, that Motivates Students to Study Ahead of their Teachers.</p>
			<p>&copy;2019 Cliptize</p>
		</footer>
    );
  }
}

export default Footer;

import React, { Component } from 'react';
import { db } from './firebase';
import { Redirect, Link } from 'react-router-dom';


class Words extends Component {

	constructor(props) {
		super(props);

		this.state = {
			clipwords: null,
			webwords: null
		};

	    this.fetchWords = this.fetchWords.bind(this);
	}


	fetchWords() {

		let ClipRef = db.collection('clipword'),
		  WebRef =  db.collection('webword'),
		  Queryclip = ClipRef.where("uploaderID", "==", this.props.user.uid),
		  Queryweb = WebRef.where("uploaderID", "==", this.props.user.uid);

		Queryclip
		.get()
		.then(collection => {
			const clipwords = collection.docs.map(doc => doc.data())
			this.setState({ clipwords })
		})

		Queryweb
		.get()
		.then(collection => {
			const webwords = collection.docs.map(doc => doc.data())
			this.setState({ webwords })
		})

	}

	componentDidMount(){
		this.props.user.uid ?
				this.fetchWords()
				:
				console.log('there is no logged in user')
	}





  render() {


  	if (this.props.user) {
	    return (

	      <section id="words-page">
			<>
				<h3>Video Keywords</h3>
				<ul>
				{
					this.state.clipwords == null ? 
					<img className="loader-spin" src="/img/loader.gif" alt="loading..." title="loading..." />
					: 
					this.state.clipwords.map(word => <li><Link to={`/word/${word.clipwords}`}> {word.clipwords} </Link></li> )
				}
				</ul>
			</>
			<>
				<h3>Page Keywords</h3>
				<ul>
				{
					this.state.webwords == null ? 
					<img className="loader-spin" src="/img/loader.gif" alt="loading..." title="loading..." />
					: 
					this.state.webwords.map(word => <li><Link to={`/word/${word.webwords}`}> {word.webwords} </Link></li> )
				}
				</ul>
			</>
		</section>

	    );
	}

	return(
	<Redirect to="/" />
	)


  }
}



export default Words;

import React, { Component } from 'react';
import { auth  } from './firebase';
import { Link, Redirect  } from 'react-router-dom';

class Header extends Component {

  constructor(props){
    super(props)

    this.state = {
      user: null,
      style: "hide"
    }

    this.logout = this.logout.bind(this);
    this.showNav = this.showNav.bind(this);
    this.hideNav = this.hideNav.bind(this);
  }

  logout() {
    auth.signOut()
      .then(() => {
        this.setState({
          user: null
        });
        this.props.data(this.state.user);
        this.setState({ style: "hide"})
      });
      return(

      <Redirect to="/rer" />
    )
  }

  showNav(){
    this.setState({ style: "show"})
  }
  hideNav(){
    this.setState({ style: "hide"})
  }



  render() {


    return (

      <><header>

        <Link id="logo" className="selected" to="/rer"><h2>Cliptize</h2></Link>
        <nav>
          <a  id="hambuger" className="flaticon-menu55" onClick={this.showNav}></a>
          <ul id="page-links" className={this.state.style}>
            <div className="nav-wrapper">
              <li><a id="hambuger-close" className="flaticon-close47" onClick={this.hideNav}></a></li>
              {

                this.props.user ?
                  <><li><Link className="selected " to="/new-clip" onClick={this.hideNav}>Upload</Link></li>
                  <li><Link className="selected " to="/words" onClick={this.hideNav}>My Keywords</Link></li> </>
                  :
                  <></>
                
              }
              <li><Link className="selected" to="/about" onClick={this.hideNav}>About</Link></li>
              {

                this.props.user ?
                  <>
                  <li><a className="selected pointer"  to="" onClick={ this.logout } >Logout</a></li>
                  <li className="profile"><img src={this.props.user.photoURL} title={this.props.user.displayName}  alt={this.props.user.displayName} /> </li> </>
                  :
                  <li><Link className="selected " to="/signin" onClick={this.hideNav}>Signin</Link></li>
                
              }
              
            </div>
          </ul>
        </nav>
        
      </header>
      
      </>
    );

  }
}

export default Header;

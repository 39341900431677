import React, { Component } from 'react';

class About extends Component {
  render() {
    return (
      
      <section id="about-page">
			<div class="text-block">
				<h3>What is Cliptize?</h3>
				<p>Cliptize is a free assignment portal, educators are using to get their class to study about the next topic, so they are mentally & psychologically prepared for the next class.</p>
				<p>With cliptize assignments, educators do not need to go through the stress of scoring students one after the other as students are automatically scored and their performance (both individually & as a group) is automatically analyzed and displayed to the teacher.</p>
			</div>
			<div class="text-block">
				<h3>What are Keywords?</h3>
				<p>Keywords are like passwords. Your teacher gives you a keyword each time he has a cliptize assignment for you. Keywords grants you access to your assignments. Keywords can be a word like 'fish' or phrase like 'Africa is beautiful'.</p>
				<p>Teachers are encouraged to use keywords that relates to the topic or assignment.</p>
			</div>
			<div class="text-block">
				<h3>Are keywords case sensitive?</h3>
				<p>Yes, "fish" and "Fish" do not mean the same thing on cliptize. So, to make sure you land safely on your teacher’s assignment, you should type in exactly what was given to you by your teacher.</p>
			</div>
			<div class="text-block">
				<h3>Must I sign in as a Teacher?</h3>
				<p>Yes. Teachers are required to sign in with either their Facebook or Gmail account. This is so you can get notified on which students attempted your assignments and their performance.</p>
			</div>
			<div class="text-block">
				<h3>I have a YouTube video I'd love to convert into an assignment?</h3>
				<p>
					<ul>
						<li>Visit youtube.com and copy the link of the video you'd like to convert into an assignment. </li>
						<li>Go to cliptize.com, sign in & click on "Upload". </li>
						<li>Paste the link of the YouTube video in the required box and choose a keyword for it. </li>
						<li>Fill in the necessary boxes and Click "upload".</li>
						<li>Share the keyword with your class. You can see a list of your assignments by clicking on "My Keywords"</li>
					</ul>
				</p>
			</div>
			<div class="text-block">
				<h3>I have a webpage I'd love to convert into an assignment?</h3>
				<p>
					<ul>
						<li>Copy the link of the webpage you wish to convert into an assignment. </li>
						<li>Go to cliptize.com, sign in & click "Upload".</li>
						<li>Paste the link of the page into the required box and choose a keyword for it.</li>
						<li>Don't forget to indicate that your assignment is a Webpage by clicking on the "Webpage?" box.</li>
						<li>Fill in the necessary boxes and Click "upload".</li>
						<li>Share the keyword with your class & remind them they must indicate they are searching for a webpage by checking the "Webpage?" box.</li>
						<li>You can see a list of your assignments by clicking on "My Keywords"</li>
						<i>Remind them they must indicate they are searching for a webword by checking the webword box.</i>
					</ul>
				</p>
			</div>
			<div class="text-block">
				<h3>What does 'Webpage?' mean?</h3>
				<p>Clicking on this box indicates you're not looking for a YouTube video but a Webpage.	</p>
				<p>The app assumes you are looking for a video if you don't click on 'Webpage?'.</p>
			</div>
			
		</section>
    );
  }
}

export default About;
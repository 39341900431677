import React, { Component } from 'react';
import Question from './Question.js';
import YouTube from 'react-youtube';
import Loader from './Loader.js';


class Clip extends Component {

	constructor(props) {
		super(props);

		this.state = {
			clipID: '',
			question: false,
			loading: true,
			ready: null

		};

		this.stopClip = this.stopClip.bind(this);
		this.stopLoading = this.stopLoading.bind(this);
	}

	componentDidMount(){
		this.setState({ clipID: this.props.property})
	}

	stopClip(e){
		this.setState({ question: true });
	}
	stopLoading(){
		this.setState({ ready: true });
		console.log('stop loading')
	}

	// setTimeout(function(){
	// 		  (a.click(); }, 3 * 1000);



  render() {

  	const opts = {
      height: '100%',
      width: '100%',
      playerVars: { 
        autoplay: 1,
        rel: 0
      }
    };


  	if (this.state.question === true ) {
  		
  		return (

  			<Question pageID='' clipID={this.state.clipID }/>

  			)
  	}

  

    return (

      <section id="video-page">

  		{this.state.ready ?

			<div id="video-controls">
				<a className="flaticon-close47 left spin" onClick={this.stopClip}></a>
			</div>
		:
			<Loader />
		}

		<YouTube
		  videoId={this.state.clipID.url }
		  className={"iframe"}
		  opts={opts} 
		  onEnd={this.stopClip}
		  onReady={this.stopLoading}
		 />
	  </section>

    );
	  

	   
  }
}

export default Clip;

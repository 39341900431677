import React, { Component } from 'react';
import { db } from './firebase';
import { Redirect } from 'react-router-dom';

class Question extends Component {


	constructor(props) {
		super(props);

		this.state = {
			clipID: null,
			pageID:null,
			user: '',
			option: '',
			response: '',
			reset: false
		};

		this.handleChange = this.handleChange.bind(this);
	    this.handleSubmit = this.handleSubmit.bind(this);
	    this.addAnswer = this.addAnswer.bind(this);
	    this.checkAnswer = this.checkAnswer.bind(this);
	}


	checkAnswer(answer){
		if (this.state.option === answer && this.state.option !== '') {
			this.setState({ response: "Right"});
	   	}
	   	else if(this.state.option !== answer && this.state.option !== '' && answer !== null) {
	   		this.setState({ response: "Wrong"});
	   	}

	   	else  if(answer === null || answer === "There is no right or wrong answer"){
	   		this.setState({ response: "Survey"});
	   	}
	   	
	   	alert('Thank you for your response!  Remember, the key to understanding ‘anything’, is to study it ‘one more time’.');
	   	this.setState({reset: true})
	}

	addAnswer(){

		const that = this;

		if (this.state.clipID !== '') {
			let Ref = db.collection('clipword'),
				  Query = Ref.where("clipwords", "==", this.state.clipID.clipwords);

			Query
			.get()
			.then(collection => {
			    const clip = collection.docs.map(doc => doc);

			    let UpdateRef = db.collection('clipword').doc(clip[0].id);

			   db.runTransaction(transaction => {
				  return transaction.get(UpdateRef).then(snapshot => {
				    const largerArray = snapshot.get('viewer');
				    largerArray.push(that.state.user + " : " + that.state.response + " : " +  this.state.option);
				    transaction.update(UpdateRef, 'viewer', largerArray);
				  });
				});
			});

			if (!this.state.clipID.answer) {
				this.setState({reset: true})
			}	

			this.checkAnswer(this.state.clipID.answer);
		}

		else if (this.state.pageID !== '') {
			let Ref = db.collection('webword'),
				  Query = Ref.where("webwords", "==", this.state.pageID.webwords);

			Query
			.get()
			.then(collection => {
			    const page = collection.docs.map(doc => doc);

			    let UpdateRef = db.collection('webword').doc(page[0].id);

			   db.runTransaction(transaction => {
				  return transaction.get(UpdateRef).then(snapshot => {
				    const largerArray = snapshot.get('viewer');
				    largerArray.push(that.state.user + "   :   " + that.state.response + "   :   " +  this.state.option);
				    transaction.update(UpdateRef, 'viewer', largerArray);
				  });
				});
			});

			if (!this.state.pageID.answer) {
				this.setState({reset: true})
			}	

			this.checkAnswer(this.state.pageID.answer);

		}
		console.log('add1')
		
	}


	componentWillMount(){
		this.setState({ clipID: this.props.clipID })
		this.setState({ pageID: this.props.pageID })
	}


	handleChange(val, event) {
		let state = this.state;
		state[val] = event.target.value;
	    this.setState({state});
	}

	handleSubmit(event) {
		event.preventDefault();
		this.addAnswer();
	}

	


  render() {

  	if (this.state.reset === true) {
  		
  		return(

			<Redirect to="/rer" />
		)

  	}

    return (

      <section id="watch-question">

			{ this.state.clipID.url  ?

				<><div id="video-clip">
					 <iframe width="100%" height="100%"
					 	title="Video Clip"
						src={ "https://www.youtube.com/embed/" + this.state.clipID.url + "?autoplay=0" }>
					 </iframe>
					
				</div></>

				:

				<div className="web-word-link">
					 <a href={this.state.pageID.url}> {this.state.pageID.url} </a>
					
				</div>
			}

			<div id="questions">

				<form onSubmit={this.handleSubmit}>
					
					{
						this.state.clipID ?

							this.state.clipID.question ?
								<div class="form-control">
									<span>{this.state.clipID.question}?</span>
									<div class="radio-button">
										<input required="required"  value={this.state.clipID.optionA}  onChange={this.handleChange.bind(this, "option")} type="radio" name="option" /> {this.state.clipID.optionA}
									</div>
									<div class="radio-button">
										<input value={this.state.clipID.optionB}  onChange={this.handleChange.bind(this, "option")} type="radio" name="option" /> {this.state.clipID.optionB}
									</div>
									<div class="radio-button">
										<input value={this.state.clipID.optionC}  onChange={this.handleChange.bind(this, "option")} type="radio" name="option" /> {this.state.clipID.optionC}
									</div>
									<div class="radio-button">
										<input value={this.state.clipID.optionD}  onChange={this.handleChange.bind(this, "option")} type="radio" name="option" /> {this.state.clipID.optionD}
									</div>
								</div>
								:
								<div class="form-control">
									<span>There is no question assigned to this clip</span>
								</div>

							:

							this.state.pageID.question ?
								<div class="form-control">
									<span>{this.state.pageID.question}?</span>
									<div class="radio-button">
										<input required="required"  value={this.state.pageID.optionA}  onChange={this.handleChange.bind(this, "option")} type="radio" name="option" /> {this.state.pageID.optionA}
									</div>
									<div class="radio-button">
										<input value={this.state.pageID.optionB}  onChange={this.handleChange.bind(this, "option")} type="radio" name="option" /> {this.state.pageID.optionB}
									</div>
									<div class="radio-button">
										<input value={this.state.pageID.optionC}  onChange={this.handleChange.bind(this, "option")} type="radio" name="option" /> {this.state.pageID.optionC}
									</div>
									<div class="radio-button">
										<input value={this.state.pageID.optionD}  onChange={this.handleChange.bind(this, "option")} type="radio" name="option" /> {this.state.pageID.optionD}
									</div>
								</div>
								:
								<div class="form-control">
									<span>There is no question assigned to this Web Page</span>
								</div>	
					} 

					<div class="form-control">
						<input value={this.state.user}  onChange={this.handleChange.bind(this, "user")} type="text" required="required" class="input" name="user" placeholder="What is your name?" />
					</div>
					

					<input type="submit" value="Finish" class="button" name="" />
				</form>

			</div>
			

		</section>
    );


  }
}

export default Question;

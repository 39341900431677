import React, { Component } from 'react';


class Loader extends Component {

  render() {

    return (

      <section id="loading">
		<div>
			<span> <img className="loader-spin" src="/img/loader.gif" alt="loading" title="Loading" /></span>
			<p>Loading...</p>
		</div>
	</section>

    );
  }
}

export default Loader;

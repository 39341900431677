import firebase from 'firebase/app';
import 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const config = {
    apiKey: "AIzaSyC4j0gFyBspjaP2ea3r4d-xzrM_TCRfS7M",
    authDomain: "cliptize.firebaseapp.com",
    databaseURL: "https://cliptize.firebaseio.com",
    projectId: "cliptize",
    storageBucket: "cliptize.appspot.com",
    messagingSenderId: "215682129532"
  };
  firebase.initializeApp(config);

 export const db = firebase.firestore();
 export const auth = firebase.auth();
 export const googleProvider = new firebase.auth.GoogleAuthProvider();
 export const facebookProvider = new firebase.auth.FacebookAuthProvider();


 const firestore = firebase.firestore();
 const settings = {/* your settings... */ timestampsInSnapshots: true};
 firestore.settings(settings);

import React, { Component } from 'react';
import { googleProvider, facebookProvider, auth  } from './firebase';
import { withRouter, Redirect } from "react-router-dom";

class Signin extends Component {

	constructor(props){
		super(props)

		this.state ={
			user: null
		}

		this.googleSignin = this.googleSignin.bind(this);
		this.facebookSignin = this.facebookSignin.bind(this);
		this.loggedIn = this.loggedIn.bind(this);
	}

  	doSignOut = () => auth.signOut();

  	loggedIn(){
  		this.props.data(this.state.user);
  		this.props.history.push('/rer');
  	}

  	googleSignin() {
  		const that = this
  		const doSignInWithGoogle = auth.signInWithPopup(googleProvider)
  		  
	      doSignInWithGoogle
	      .then(
	      	function(result){

	      		const user = result.user

	      		that.setState({ user });
	      		user ? that.loggedIn() : console.log('There is no user returned')
	      	})
	      .catch(function(error) {
			    console.error("Error with user signin: ", error);
			});
	        
	};

	facebookSignin() {
  		const that = this
  		const doSignInWithFacebook = auth.signInWithPopup(facebookProvider)
  		  
	      doSignInWithFacebook
	      .then(
	      	function(result){

	      		const user = result.user

	      		that.setState({ user });
	      		user ? that.loggedIn() : console.log('There is no user returned')
	      	})
	      .catch(function(error) {
			    console.error("Error with user signin: ", error);
			});
	        
	};



  render() {

	    if (this.props.user) {
	    	return(
    		<Redirect to="/rer" />
    		)	
	    }

	    return (
	    	
				<section id="signin">
					<p>Select an option below to Sign in</p>
					<span onClick={this.googleSignin} class="signin-option">Google</span>
					<span onClick={this.facebookSignin} class="signin-option">Facebook</span>
					<div id="info-box">
					<p>Your private information are safe, as we do not have access to them.</p>
					</div>
				</section>
		    );

  }
}

export default withRouter(Signin);

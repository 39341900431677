import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';


class Redirectr extends Component {

  render() {

    return(

			<Redirect to="/" />
		)
  }
}

export default Redirectr;

import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class FOF extends Component {


  render() {


    return (

      <section>

        <p>Page not found</p>
        <Link className="selected" to="/">Back to Home Page</Link>
        
      </section>
    );

  }
}

export default FOF;

import React, { Component } from 'react';
import { BrowserRouter as Router , Route, Switch } from 'react-router-dom';
import Home from './Home.js';
import Signin from './Signin.js';
import About from './About.js';
import AddPage from './AddPage.js';
import Header from './Header.js';
import Footer from './Footer.js';
import FOF from './FOF.js';
import Words from './Words.js';
import Word from './Word.js';
import Redirectr from './Redirect.js';
import { auth  } from './firebase';



class App extends Component{
  constructor(props){
    super(props);

    this.state ={
      user: null
    }
  }

  updateUser = (value) => {

      this.setState({
        user: value
      });

  }

  componentDidMount(){
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.setState({ user });
      } 
    });
  }


  

  render(){

    const user = this.state.user;
    
    return(
      <Router>
        
        <div>
          <Header user={user} data={this.updateUser} />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/rer" component={Redirectr} />
            <Route exact path="/signin" render={ () => <Signin user={user} data={this.updateUser} />} />
            <Route exact path="/about" component={About} />
            <Route exact path="/new-clip" render={ () => <AddPage user={user}  />}  />
            <Route exact path="/words" render={ () => <Words user={user}  />}  />
            <Route exact path="/word/:id" render={ ({ match }) => <Word user={user} match={match} /> } />
            <Route component={FOF} />
          </Switch>  
          <Footer />
        </div>
        
      </Router>
      
    )
  }
}

export default App;

import React, { Component } from 'react';
import { db } from './firebase';
import {  Redirect } from "react-router-dom";
import Loader from './Loader.js';


class AddPage extends Component {

	constructor(props) {
		super(props);

		this.state = {
			url: null,
			word: null,
			question: null,
			option1: null,
			option2: null,
			option3: null,
			option4: null,
			answer: null,
			wordtype: false,
			questionoption: false,
			surveyoption: false,
			submitted: null,
			loading: null
		};

		this.handleChange = this.handleChange.bind(this);
	    this.handleSubmit = this.handleSubmit.bind(this);
	    this.handleCheck = this.handleCheck.bind(this);
	    this.addClipWord = this.addClipWord.bind(this);
	    this.addWebWord = this.addWebWord.bind(this);
	}


	addClipWord(){
		const that = this;

		let Ref = db.collection('clipword');

		Ref.add({
			    url: this.state.url.slice(-11),
			    clipwords: this.state.word,
			    question: this.state.question,
			    optionA: this.state.option1,
			    optionB: this.state.option2,
			    optionC: this.state.option3,
			    optionD: this.state.option4,
			    answer: this.state.answer,
			    viewer: [],
			    uploaderID: this.props.user.uid,
			    uploaderName: this.props.user.displayName
			})
			.then(function(docRef) {
			    that.setState({	url: null,
								word: null,
								question: null,
								option1: null,
								option2: null,
								option3: null,
								option4: null,
								answer: null
			      			});
			    alert("New Word added successfully");
			    that.setState({submitted: true})
			})
			.catch(function(error) {
			    console.error("Error adding document: ", error);
			});
	}
	addWebWord(){

		const that = this;

		let Ref = db.collection('webword');

		Ref.add({
			    url: this.state.url,
			    webwords: this.state.word,
			    question: this.state.question,
			    optionA: this.state.option1,
			    optionB: this.state.option2,
			    optionC: this.state.option3,
			    optionD: this.state.option4,
			    answer: this.state.answer,
			    viewer: [],
			    uploaderID: this.props.user.uid,
			    uploaderName: this.props.user.displayName
			})
			.then(function(docRef) {
			    that.setState({	url: null,
								word: null,
								question: null,
								option1: null,
								option2: null,
								option3: null,
								option4: null,
								answer: null
			      			});
			    alert("New Word added successfully");
			    that.setState({submitted: true})
			})
			.catch(function(error) {
			    console.error("Error adding document: ", error);
			});
	}

	handleCheck(val, e) {
		let state= this.state;
		state[val] = e.target.value;
		this.setState({ state });
	}

	handleChange(val, event) {
		let state = this.state;
		state[val] = event.target.value;
	    this.setState({state});
	}

	handleSubmit(event) {
		this.setState({loading: true});

		if(this.state.questionoption && this.state.questionoption !== "question"){

  			this.setState({answer: "There is no right or wrong answer"})
  		}
		
		this.state.wordtype ?

			this.addWebWord()

			:
		
			this.addClipWord();

		
	    event.preventDefault();
	}

	componentDidMount(){

	}

  render() {

  	if (this.state.submitted) {
	    	return(
    		<Redirect to="/rer" />
    		)	
	    }
	if (this.state.loading === true ) {
  		
  		return (

  			<Loader />

  			)
  	}

  	if (this.props.user) {

	    return (

	      <section id="add-word">

				<form onSubmit={this.handleSubmit}>
					<div class="form-control">
						<input required="required" value={this.state.url}  onChange={this.handleChange.bind(this, "url")} type="text" class="input" placeholder="Paste link of video or web page you wish to give as assignment... " name="url" />
						<div class="radio-button inline">
							<input type="checkbox"  name="wordtype" checked={this.state.wordtype} onChange={this.handleCheck.bind(this, "wordtype")} />
							Webpage?
						</div>
					</div>
					<div class="form-control">
						<input required="required" value={this.state.word}  onChange={this.handleChange.bind(this, "word")} type="text" class="input" placeholder="Choose a keyword for accessing this assignment" name="word" />
						<div class="radio-button inline">
							<input type="radio"  name="questionoption" value="question"  onChange={this.handleCheck.bind(this, "questionoption")} />
							Add Question?
						</div>
						<div class="radio-button inline">
							<input type="radio"  name="questionoption" value="survey"  onChange={this.handleCheck.bind(this, "questionoption")} />
							Add Survey?
						</div>
					</div>
					
					{this.state.questionoption  ?

						<>
							<div class="form-control">
								<span>Question</span>
								<textarea required="required" value={this.state.question}  onChange={this.handleChange.bind(this, "question")} class="input text-input" placeholder="Type in your question" name="question"></textarea>
							</div>
							<div class="form-control">
								<span>Answer options</span>
								<input required="required" value={this.state.option1}  onChange={this.handleChange.bind(this, "option1")} type="text" class="input" placeholder="Option A" name="option1" />
								<input required="required" value={this.state.option2}  onChange={this.handleChange.bind(this, "option2")} type="text" class="input" placeholder="Option B" name="option2" />
								<input required="required" value={this.state.option3}  onChange={this.handleChange.bind(this, "option3")} type="text" class="input" placeholder="Option C" name="option3" />
								<input required="required" value={this.state.option4}  onChange={this.handleChange.bind(this, "option4")} type="text" class="input" placeholder="Option D" name="option4" />

								{this.state.questionoption == "question" ?

									<input required="required" value={this.state.answer}  onChange={this.handleChange.bind(this, "answer")}type="text" class="input" placeholder="Copy Answer from whichever option above & Paste it here ...." name="answer" />

									:
									
									<><span>There is no wrong or right answer </span>
									<input required="required" disabled="disabled" value="There is no right or wrong answer"  onChange={this.handleChange.bind(this, "answer")} type="text" className="input hidden" placeholder="There is no right or wrong answer" name="answer" /></>}
							</div>
						</>

						:

						<></>

					 }
					
					<input type="submit" class="button" value="Upload" name="" />
				</form>
						
			</section>

	    );

	}
	return(
		<Redirect to="/signin" />
		)


  }
}



export default AddPage;

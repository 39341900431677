import React, { Component } from 'react';
import { db } from './firebase';
import Clip from './Clip.js';
import Loader from './Loader.js';
import Question from './Question.js';


class Home extends Component {

	constructor(props) {
		super();

		this.state = {
			word: '',
			clip: '',
			page: '',
			clipReturned: false,
			wordtype: false,
			hint: {
				message: '',
				styles: ''
			},
			loader: null,
			question: null
		};

		this.handleChange = this.handleChange.bind(this);
	    this.handleSubmit = this.handleSubmit.bind(this);
	    this.handleCheck = this.handleCheck.bind(this);
	    this.fetchClip = this.fetchClip.bind(this);
	    this.fetchPage = this.fetchPage.bind(this);
	    this.loadPage = this.loadPage.bind(this);
	    this.playClip = this.playClip.bind(this);
	    this.show = this.show.bind(this);
	}

	playClip(){
		this.setState({ clipReturned: true })
	}

	fetchClip(){
		
		let Ref = db.collection('clipword'),
				  Query = Ref.where("clipwords", "==", this.state.word);

		
		Query
		.get()
		.then(collection => {

			    const clip = collection.docs.map(doc => doc.data())
			    this.setState({ clip: clip[0] })
			    
			    if (clip[0]) {
			    	this.playClip();
			    }

		    	this.setState({hint: {
		    		message: "Oops!.. please type in the exact keyword, same case, no added spaces. Also, click the 'box' above if your keyword is 'a Webpage'.",
		    		styles: "red"
		    		}
		    	});	
		 })
		.then( () => {
			this.setState({loader: false})
		})
	}

	loadPage(url){
		   // let a = document.createElement('a');
		   // // a.href = url;
		   // a.setAttribute('href', url);
		   // a.setAttribute('target', '_blank');
		   // a.onclick = function () {
		   // 	console.log('fregn clicked')
		   // }
		   // console.log(a);

		   // // a.click(); 

		   var win = window.open(url);
           win.focus(); 
	}

	show(){
		 console.log(this.state.page.url)
	}

	fetchPage(){
		
		let Ref = db.collection('webword'),
				  Query = Ref.where("webwords", "==", this.state.word);

		
		Query
		.get()
		.then(collection => {
			if(collection){

			    const page = collection.docs.map(doc => doc.data())
			    this.setState({ page: page[0] })

			    if (page[0]) {
			    	this.loadPage(this.state.page.url);
			    	this.setState({ question: true });
			    }

		    	this.setState({hint: {
		    		message: "Oops!.. please type in the exact keyword, same case, no added spaces. Also, click the 'box' above if your keyword is 'a Video'.",
		    		styles: "red"
		    		}
		    	});
			 }

			 	
		 })
		.then( () => {
			this.setState({loader: false})
		})
	}

	componentDidMount(){
		
	}

	handleCheck(val, e) {
		let state= this.state;
		state[val] = e.target.checked;
		this.setState({ state });
	}

	handleChange(val, event) {
		let state = this.state;
		state[val] = event.target.value;
	    this.setState({state});
	}

	handleSubmit(event) {
		this.setState({loader: true})

		this.state.wordtype ?

			this.fetchPage()

			:

			this.fetchClip();

	    event.preventDefault();
	}



  render() {

  	if (this.state.clipReturned === true) {
  		return(

			<Clip property={this.state.clip} /> 

  			);
  	}

  	if (this.state.question) {
  		
  		return (

  			<Question pageID={this.state.page } clipID=''/>

  			)
  	}

  	if (this.state.loader === true ) {
  		
  		return (

  			<Loader />

  			)
  	}
  	
    return (

      <section id="watch-form">
	      <div class="search-logo">
				<img src="img/logo1.png" alt="Logo" />
			</div>
			<form onSubmit={this.handleSubmit}>
				<input className="input" value={this.state.word} onChange={this.handleChange.bind(this, "word")} type="text" name="url" placeholder="Got a Keyword from your Teacher today? Type it in here.." />
				<div class="radio-button">
					<input type="checkbox"  name="wordtype" checked={this.state.wordtype} onChange={this.handleCheck.bind(this, "wordtype")} />
					&nbsp; <i>Webpage?</i>
				</div>
				<input className="button" type="submit" value="GO!" name="" />
			</form>
			<div id="info-box">
				<p className={this.state.hint.styles}>{this.state.hint.message}</p>
			</div>

	</section>
    );
  }
}

export default Home;
